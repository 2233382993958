import { browser } from '$app/environment';
import { PUBLIC_PADDLE_TOKEN } from '$env/static/public';
import { isCheckoutEnabled, isProd } from '$lib/stores/globals';

import * as Sentry from '@sentry/browser';
import { get_params_of_interest, logEvent, track_initiate_checkout, track_purchase } from './logs';

let isInstalled = false;
let triedToInstall = false;

/**
 * 
 * @param {object} options
 * @param {function=} options.loaded
 */
export function install_paddle(options = {}) {
    const loaded = options.loaded || (() => { });

    if (!isCheckoutEnabled) {
        console.log('checkout disabled');
        return;
    }

    if (!isInstalled && !triedToInstall && browser) {
        console.log('install_paddle', options?.loaded ? 'with action' : 'without action');


        triedToInstall = true;

        let d = document;
        let s = d.createElement('script');
        s.async = true;
        d.body.appendChild(s);

        s.onload = () => {
            isInstalled = true;
            console.log('Paddle loaded');
            if (!isProd) {
                console.log('Paddle sandbox mode');
                window.Paddle.Environment.set('sandbox');
            }

            window.Paddle.Initialize({
                token: PUBLIC_PADDLE_TOKEN,
                eventCallback(data) {
                    // console.log('eventCallback', data.name, data.data);
                    switch (data.name) {
                        case 'checkout.loaded': 
                            track_initiate_checkout();
                            break;

                        case 'checkout.closed':
                            console.log('checkout.closed');
                            document.dispatchEvent(new CustomEvent('paddle_close', { detail: data.data }));
                            break;

                        case 'checkout.completed':
                            console.log('checkout.completed');
                            document.dispatchEvent(new CustomEvent('paddle_success', { detail: data.data }));
                            track_purchase(parseFloat(data.data.totals.total) - parseFloat(data.data.totals.tax));
                            break;
                        case 'checkout.customer.created':
                        case 'checkout.customer.updated':
                            logEvent('purchase:payment_step_2');
                            console.log('checkout.customer.created/updated');
                            if (data.data?.customer?.id) localStorage.setItem('ctm_id', data.data.customer.id);
                            if (data.data?.customer?.address?.id) localStorage.setItem('add_id', data.data.customer.address.id);
                            if (data.data?.customer?.email) localStorage.setItem('ctm_email', data.data.customer.email);
                            break;

                        case 'checkout.customer.removed':
                            console.log('checkout.customer.removed');
                            localStorage.removeItem('ctm_id');
                            localStorage.removeItem('add_id');
                            break;

                        case 'checkout.payment.failed':
                            console.log('checkout.payment.failed');
                            logEvent('purchase:payment_failed', {payment_type: data?.data?.payment?.method_details?.type});
                            break;
                        default:
                        // console.log(data);
                    }
                },
            });


            loaded && loaded();
        };

        s.onerror = (e) => {
            console.error('Paddle Loading Error', e);
            Sentry.captureException('Paddle Loading Error');
        }
        s.src = 'https://cdn.paddle.com/paddle/v2/paddle.js';
    }
}

export function paddle_purchase({ price_id, email = null, discount_id = null }) {
    // console.log('paddle_purchase', price_id, email);
    if (!isCheckoutEnabled) {
        console.log('checkout disabled');
        return;
    }
    if (!window.Paddle) {
        install_paddle({ loaded: paddle_purchase.bind(null, { price_id, email, discount_id }) });
        return;
    }

    const theme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

    let ctm_id = localStorage.getItem('ctm_id') || null;
    let add_id = localStorage.getItem('add_id') || null;
    let ctm_email = localStorage.getItem('ctm_email') || null;

    let options = {
        settings: {
            theme,
            locale: 'en',
        },
        items: [
            {
                priceId: price_id,
                quantity: 1
            }
        ],
        customData: get_params_of_interest(),

    };

    if (ctm_id && add_id) {
        options.customer = {
            id: ctm_id,
            address: {
                id: add_id
            }
        };
    } else if (email || ctm_email) {
        options.customer = {
            email: email || ctm_email
        };
    }

    if (discount_id) options.discountId = discount_id;

    window.Paddle.Checkout.open(options);
}
